import diagramm from '../images/ANO.Component diagram.svg'
import video from '../videos/featurevideo.mp4'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from '../styles/Analytics.module.css'
import { Mousewheel, Pagination, Parallax } from 'swiper/modules';
import ScrollButton from './ScrollButton.js'

const Analytics = ({setShowNavbar}) => {

    const productRef = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {
        setShowNavbar(true)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const handleClick = () => {
        productRef.current.scrollIntoView({behavior: "smooth"});
    }

      
    return (
        <>
        <div className={styles.productTitleContainer}>
            <h1 className={styles.analyticsTitle}>Система объектовой безопасности с видеоаналитикой ИИ</h1>
                <h2>
                    Представляем вашему вниманию систему объектовой безопасности с видеоаналитикой на базе ИИ, 
                    разработанную для обеспечения безопасности и аналитики в реальном времени. Эта система спроектирована 
                    с использованием распределенной архитектуры, которая позволяет ей легко масштабироваться и адаптироваться 
                    к различным условиям и требованиям безопасности, от частных до муниципальных объектов.
                </h2>
                <button className={styles.downButton} onClick={handleClick}>
                    <svg width="586" height="335" viewBox="0 0 586 335" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="543.704" y="0.5" width="59.5512" height="413.239" rx="25" transform="rotate(45 543.704 0.5)" fill="white"/>
                        <rect x="334.813" y="292.704" width="59.5512" height="413.239" rx="25" transform="rotate(135 334.813 292.704)" fill="white"/>
                    </svg>
                </button>
            <div class={styles.titleWave}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class={styles.titleWaveShapeFill}></path>
                </svg>
            </div>
        </div>
        <div ref={productRef} className={styles.selectedProductContainer}>
            <div className={styles.descriptionSection}>
                <div className={styles.descriptionSwiper}>
                    <Swiper
                        direction='horizontal'
                        loop={true}
                        slidesPerView={'auto'}
                        spaceBetween={40}
                        mousewheel={true}
                        pagination={{
                        clickable: true,
                        }}
                        modules={[Mousewheel, Pagination, Parallax]}
                        className={styles.mySwiper}
                    >
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>В основе нашей системы лежат аналитические серверы, распределенные по различным регионам, 
                            каждый из которых способен обрабатывать данные с камер видеонаблюдения в своем районе. 
                            Эти серверы оснащены <b>современными компонентами</b> для обработки медиапотоков и взаимодействия с 
                            базами данных, обеспечивая тем самым высокую производительность и надежность.</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>Центральный сервер собирает и анализирует информацию со всех аналитических серверов, предоставляя 
                            единую точку управления и мониторинга. Благодаря использованию технологий, таких как HLS и WebRTC, 
                            система <b>гарантирует бесперебойную передачу</b> медиапотоков в реальном времени, позволяя оперативно 
                            реагировать на любые события.</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>Система предлагает <b>гибкие настройки конфигурации</b> для адаптации под различные сценарии использования, 
                            включая настройку параметров камер и определение зон и объектов аналитики. Такой подход обеспечивает 
                            возможность точной настройки системы для удовлетворения конкретных потребностей в безопасности.</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>Пользовательские приложения для администрирования и мониторинга предлагают <b>удобный интерфейс</b> для управления 
                            системой, позволяя вам всегда оставаться в курсе происходящего на вашем объекте. Система также включает в себя 
                            механизмы для асинхронного обмена сообщениями, обеспечивающие эффективную координацию между 
                            различными компонентами системы.</p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>Эта система представляет собой <b>мощное и гибкое решение</b> для обеспечения безопасности, способное адаптироваться к 
                            широкому спектру требований и условий, обеспечивая при этом высокую производительность и надежность.</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={styles.descriptionImg}>
                    <img src={require('../images/description-image.png')} alt="" />
                </div>
            </div>
            <div className={styles.structure}>
                <div class={styles.structureWave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={styles.structureWaveShapeFill}></path>
                    </svg>
                </div>
                <h2>Структура системы</h2>
                <p>
                    Наша система безопасности использует <b>распределённую архитектуру</b>, что означает, 
                    что она состоит из нескольких аналитических серверов, разбросанных по разным регионам, 
                    и одного центрального агрегационного сервера. Аналитические серверы, расположенные в 
                    различных местах (названные Region 1, Region 2 и так далее до Region N), занимаются 
                    обработкой данных с камер наблюдения. Затем эта информация направляется на центральный 
                    агрегационный сервер (Region 0), который объединяет и анализирует данные со всех аналитических 
                    серверов. Эта <b>структура позволяет системе эффективно масштабироваться</b>, упрощает добавление 
                    новых камер и регионов, а также <b>облегчает управление</b> всей системой, делая её более гибкой и 
                    мощной в работе с большими объёмами данных.
                </p>
                <div className={styles.imageWrapper}>
                    <img src={diagramm} alt="" />
                </div>
                <div className={styles.structureBottomWave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class={styles.structureBottomWaveShapeFill}></path>
                    </svg>
                </div>
            </div>
            <div className={styles.systemParts}>
                <h2>Внутренняя структура системы</h2>
                <div className={styles.parts}>
                    <div className={styles.part}>
                        <p>
                            Наши аналитические серверы работают с данными от камер наблюдения, используя специализированные 
                            инструменты для обработки видео (MediaMTX) и хранения информации (PostgreSQL), а также системы 
                            для настройки и мониторинга состояния работы. Эти серверы расположены в различных местах, чтобы 
                            локально обрабатывать видеоданные.
                        </p>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/servers-icon.png')} alt="" />
                        </div>
                    </div>
                    <div className={styles.part}>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/analytics-server-icon.png')} alt="" />
                        </div>
                        <p>
                            Центральный сервер собирает всю информацию с аналитических серверов, анализирует её, 
                            используя похожие инструменты, и дополнительно применяет Redis для эффективной работы с 
                            данными в реальном времени. Это позволяет быстро обрабатывать большие объемы 
                            данных из разных источников.
                        </p>
                    </div>
                    <div className={styles.part}>
                        <p>
                            Мы используем современные технологии, такие как HLS и WebRTC, для передачи видеопотоков 
                            без задержек, что дает возможность наблюдать за происходящим в реальном времени.
                        </p>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/web-icon.png')} alt="" />
                        </div>
                    </div>
                    <div className={styles.part}>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/check-icon.png')} alt="" />
                        </div>
                        <p>
                            Система включает в себя удобные приложения для пользователей (Watcher и Admin), 
                            через которые можно просматривать видео и управлять настройками системы наблюдения и аналитики, 
                            делая её доступной для людей без специальных технических знаний.
                        </p>
                    </div>
                    <div className={styles.part}>
                        <p>
                            Для обеспечения связи между всеми частями системы мы используем RabbitMQ, сервис, который 
                            позволяет компонентам системы обмениваться сообщениями асинхронно, то есть отправлять и получать информацию, 
                            не мешая друг другу работать.
                        </p>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/system-icon.png')} alt="" />
                        </div>
                    </div>
                    <div className={styles.part}>
                        <div className={styles.partImgWrapper}>
                            <img src={require('../images/icons/functionality-icon.png')} alt="" />
                        </div>
                        <p>
                            Благодаря такой структуре и использованию передовых технологий, наша 
                            система способна выполнять задачи по обеспечению безопасности и анализу видеоданных 
                            на высоком уровне, обладая при этом гибкостью и возможностью масштабирования для работы 
                            на больших территориях.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.features}>
                <div className={styles.featuresWave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={styles.featuresWaveShapeFill}></path>
                    </svg>
                </div>
                <h2>Функциональные особенности и возможности</h2>
                <p>
                    Наша система предлагает <b>широкие возможности для настройки</b> аналитики видеонаблюдения, 
                    позволяя пользователю тонко настроить параметры камер, такие как качество изображения, 
                    способ кодирования видео и частоту кадров. Вы также можете выбирать, какие именно объекты 
                    или действия должна система отслеживать, и определять специфические зоны для детального анализа.
                </p>
                <p>
                    Эта гибкость делает нашу систему <b>идеально подходящей для самых разных условий использования</b>, 
                    от частных домовладений и складских помещений до объектов муниципального значения, где требуется 
                    особенно высокий уровень контроля безопасности.
                </p>    
                <div className={styles.videoWrapper}>
                    <video autoplay='true' loop src={video} width='100%'></video>
                </div>
            </div>
        </div>
        <ScrollButton></ScrollButton>
        </>
    );
}
 
export default Analytics;