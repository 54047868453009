import Contacts from "./Contacts";
import Products from "./Products";
import Contractors from "./Contractors";
import About from "./About"
import '../styles/MainPage.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ScrollButton from "./ScrollButton";

const MainPage = ({setShowNavbar}) => {
    useEffect(() => {
        setShowNavbar(true)
    }, [])
    return (
        <>
            <section id="about">
                <About></About>
            </section>
            <section id="products">
                <Products></Products>
            </section>
            <section id="contacts">
                <Contacts></Contacts>
            </section>
            <section id='contractors'>
                <Contractors></Contractors>
            </section>
            <ScrollButton></ScrollButton>
        </>
    );
}
 
export default MainPage;