import { Link } from 'react-router-dom'
import styles from '../styles/ErrorPage.module.css'
import { useEffect } from 'react';

const ErrorPage = ({setShowNavbar}) => {

    useEffect(() => {
        setShowNavbar(false);
    }, [])

    return (
        <div className={styles.errorPage}>
            <h1 className={styles.errorPageTitle}>Такого ресурса не существует</h1>
            <Link className={styles.errorPageLink} to='/'>Вернуться на главную страницу</Link>
        </div>
    );
}
 
export default ErrorPage;