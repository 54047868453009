import './App.css';
import MainPage from './components/MainPage';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Analytics from './components/Analytics'
import PrivateAccount from './components/PrivateAccount';
import ErrorPage from './components/ErrorPage';
import Navbar from './components/Navbar';

function App() {
  
  const [showNavbar, setShowNavbar] = useState(false)

  return (
    <>
    {showNavbar && <Navbar></Navbar>}
      <Routes>
        <Route path='/' element={<MainPage setShowNavbar={setShowNavbar}></MainPage>}></Route>
        <Route path='/AnalyticsService' element={<Analytics setShowNavbar={setShowNavbar}></Analytics>}></Route>
        <Route path='/SecurityService' element={<PrivateAccount setShowNavbar={setShowNavbar}></PrivateAccount>}></Route>
        <Route path='*' element={<ErrorPage setShowNavbar={setShowNavbar}></ErrorPage>}></Route>
      </Routes>
    </>
  );
}

export default App;
