import { useEffect, useState } from 'react';
import styles from '../styles/Navbar.module.css'
import { NavHashLink } from "react-router-hash-link"

const Navbar = () => {

    const [mode, setMode] = useState('default');
    const [height, setHeight] = useState(0)

    const scrollHandler = () => {
        const heightToShow = 10 ;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToShow) {  
            setMode('active')
       } else {
            setMode('default');
       }  
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [])

    return (
      <div className={`${styles.navigation} ${styles[mode]}`}>
        <div className={styles.navContent}>
            <NavHashLink className={styles.logo} smooth to="/"><img src={require('../images/diagramma-logo.png')} alt="" /></NavHashLink>
            <NavHashLink smooth to="/#about" className={styles.navigationLink}>О нас</NavHashLink>
            <NavHashLink smooth to="/#products" className={styles.navigationLink}>Услуги и решения</NavHashLink>
            <NavHashLink smooth to="/#contacts" className={styles.navigationLink}>Контакты</NavHashLink>
            <NavHashLink smooth to="/#contractors" className={styles.navigationLink}>Партнёры</NavHashLink>
        </div>
    </div>
    );
}
 
export default Navbar;