import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from '../styles/PrivateAccount.module.css'
import yoniti from '../images/Yoniti.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, EffectFade } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/mousewheel";
import ScrollButton from './ScrollButton.js'


const PrivateAccount = ({setShowNavbar}) => {

    const navigate = useNavigate()
    const productRef = useRef(null);

    useEffect(() => {
        setShowNavbar(true)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleClick = () => {
       productRef.current.scrollIntoView({behavior: "smooth"}); 
    }

    return (
        <>
            <div className={styles.productTitleContainer}>
                <h1 className={styles.privateAccountTitle}>
                    Личный кабинет пользователя<br/>индивидуальной системы видеонаблюдения для провайдера услуг
                </h1>
                <div className={styles.descriptionSwiper}>
                    <Swiper
                        direction='vertical'
                        mousewheel={{
                            sensitivity: 1,
                            releaseOnEdges: true,
                        }}
                        effect={'fade'}
                        fadeEffect={{crossFade: true}}
                        modules={[EffectFade, Mousewheel]}
                        className={styles.mySwiper}
                    >
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>
                            Откройте для себя новый уровень управления и наблюдения за безопасностью вашего пространства с нашим 
                            инновационным продуктом - Личный кабинет пользователя для индивидуальной системы видеонаблюдения. 
                            Этот инструмент предназначен специально для провайдеров услуг, стремящихся предоставить своим клиентам 
                            не только высококачественное видеонаблюдение, но и уникальный, настраиваемый пользовательский опыт.
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>
                            С нашим решением, вы получаете доступ к передовым технологиям и функциям, которые обеспечивают превосходное качество 
                            воспроизведения видео, гибкие настройки просмотра и удобное управление архивами видеозаписей. Ваш личный кабинет 
                            позволит вам в любой момент получить доступ к живым видеопотокам и видеоархиву, настроить просмотр под свои нужды 
                            и даже осуществить воспроизведение видео в обратном порядке, обеспечивая полный контроль над системой видеонаблюдения
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>
                            Воспользуйтесь возможностью оптимизировать процесс мониторинга и аналитики, делая его максимально эффективным и удобным. 
                            Наш продукт не просто повышает уровень безопасности – он делает процесс наблюдения интуитивно понятным и доступным, 
                            давая вам необходимые инструменты для защиты и управления вашим пространством с новой степенью прозрачности и контроля.
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <p>
                                <button className={styles.downButton} onClick={handleClick}>
                                    <svg width="586" height="335" viewBox="0 0 586 335" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="543.704" y="0.5" width="59.5512" height="413.239" rx="25" transform="rotate(45 543.704 0.5)" fill="white"/>
                                        <rect x="334.813" y="292.704" width="59.5512" height="413.239" rx="25" transform="rotate(135 334.813 292.704)" fill="white"/>
                                    </svg>
                                </button>
                            </p>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={styles.wave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className={styles.shapeFill}></path>
                    </svg>
                </div>
            </div>
            <div className={styles.selectedProductContainer}>
                <div ref={productRef} className={styles.systemParts}>
                    <h2>Основные составные части</h2>
                    <p className={styles.systemPartsSubtitle}>
                        В вашем личном кабинете для управления системой видеонаблюдения есть следующие ключевые элементы
                    </p>
                    <div className={styles.systemPartsContentWrapper}>
                        <div className={styles.systemPartsContent}>
                            <div className={styles.systemPartsInfo}>
                                <div className={styles.systemPartsInfoItem}>
                                    <div className={styles.systemPartsInfoItemTitle}>Веб-клиент</div>
                                    <p>
                                        Это та часть системы, с которой вы непосредственно взаимодействуете 
                                        через ваш браузер. Она позволяет вам просматривать видео, управлять настройками и 
                                        делать все необходимые операции онлайн
                                    </p>
                                </div>
                                <div className={styles.systemPartsInfoItem}>
                                    <div className={styles.systemPartsInfoItemTitle}>Nginx</div>
                                    <p>
                                        Это специальное программное обеспечение, которое работает как посредник 
                                        между вами и сервером, где хранятся данные. Оно помогает загружать веб-страницы 
                                        быстрее и безопаснее, а также отвечает за доставку видео и другой информации, 
                                        необходимой для вашего личного кабинета
                                    </p>
                                </div>
                                <div className={styles.systemPartsInfoItem}>
                                        <div className={styles.systemPartsInfoItemTitle}>Java сервер</div>
                                    <p>
                                        Эта часть отвечает за воспроизведение видеоархивов. Вы можете выбирать 
                                        скорость просмотра видео, как вперед, так и назад, чтобы точно найти момент, который вас интересует
                                    </p>
                                </div>
                                <div className={styles.systemPartsInfoItem}>
                                        <div className={styles.systemPartsInfoItemTitle}>Gstreamer pipleine (1)</div>
                                    <p>
                                        Это технология, которая обрабатывает видеофайлы, например, превращая 
                                        их из одного формата в другой, чтобы вы могли их просматривать. В данном случае, она отвечает 
                                        за расшифровку видеофайлов и их воспроизведение с выбранной вами скоростью
                                    </p>
                                </div>
                                <div className={styles.systemPartsInfoItem}>
                                        <div className={styles.systemPartsInfoItemTitle}>Gstreamer pipeline (2)</div>
                                    <p>
                                        Это похожая технология, но она берет уже обработанный видеопоток 
                                        и преобразует его в формат, подходящий для онлайн-просмотра, сохраняя файлы в таком виде, 
                                        чтобы вы могли легко их просматривать позже
                                    </p>
                                </div>
                            </div>
                            <div className={styles.systemPartsImg}>
                                <img src={yoniti} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.systemFeatures}>
                    <div className={styles.featuresWave}>
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={styles.featuresWaveShapeFill}></path>
                        </svg>
                    </div>
                    <h2>Функциональные особенности и возможности</h2>
                    <div className={styles.imgWrapper}>
                        <img src={require('../images/features-image.png')} alt="" />
                    </div>
                    <div className={styles.systemFeaturesList}>
                        <p>
                            Ваш личный кабинет в системе видеонаблюдения предлагает <b>множество 
                            удобных функций</b> и возможностей для мониторинга. Вы можете в реальном 
                            времени наблюдать за происходящим через камеры благодаря <b>интуитивно 
                            понятному интерфейсу,</b> который позволяет настраивать вид отображения 
                            камер на экране по вашему усмотрению.
                        </p>
                        <p>
                            Интерфейс включает в себя продуманную панель управления, с помощью 
                            которой вы можете <b>легко переключаться между видео с разных камер</b> и 
                            просматривать архивы записей на любой скорости, как вперед, так и назад. 
                            Это особенно полезно, если вам нужно тщательно изучить какое-либо событие. 
                        </p>
                        <p>
                            Выбор конкретного видео происходит через интерактивную временную шкалу и 
                            календарь, что <b>значительно упрощает поиск</b> нужных записей, избавляя вас 
                            от необходимости вручную просматривать список файлов. Эта функция не 
                            только <b>экономит ваше время,</b> но и делает процесс <b>более удобным и эффективным.</b>
                        </p>
                        <p>
                            Кроме того, система обеспечивает <b>возможность моментального доступа</b> к 
                            критически важным моментам видеозаписей, используя интеллектуальные алгоритмы 
                            поиска и фильтрации, что делает её <b>незаменимым инструментом</b> в обеспечении 
                            безопасности и контроле за объектами.
                        </p>
                    </div>
                </div>
            </div>
            <ScrollButton></ScrollButton>
        </>
    );
}
 
export default PrivateAccount;