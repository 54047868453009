import React from "react";
import { HashLink } from "react-router-hash-link";
import styles from '../styles/Products.module.css'

const Products = () => {

    return (
        <div className="content-container">
            <h1>Услуги и решения</h1>
                <div className={styles.product}>
                    <h2>Услуги</h2>
                    <div className={styles.productItem}>
                        <p>
                            <b>Разработка ПО:</b> Мы создаем ПО, используя передовые методологии DevOps и CI/CD для обеспечения высокой эффективности и качества.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Разработка систем ИИ:</b> Мы разрабатываем и обучаем нейронные сети для работы с уникальными задачами, открывая новые возможности для вашего бизнеса.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Специализированное ПО для ПЛИС:</b> Наш опыт включает разработку сложного и высокопроизводительного программного обеспечения для ПЛИС от ведущих производителей, таких как AMD (Xilinx) и Intel (Altera).
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Цифровая обработка сигналов:</b> Наша команда осуществляет разработку алгоритмов цифровой обработки сигналов для любых систем, в том числе и новейших, таких как LTE-Advanced, 5G. Мы разрабатываем высокоэффективные алгоритмы для реализации в DSP и ПЛИС.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Разработка ПО для SDR Платформ:</b> Мы создаем собственные SDR платформы на базе новейших компонентов, представленных на рынке и разрабатываем специализированное ПО как для SDR собственной разработки, так и для других популярных платформ.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Мультиплатформенные Решения:</b> Наша команда имеет опыт разработки ПО для интегрированных систем на базе иностранных и отечественных процессоров, использующих  архитектуру ARM и MIPS.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Промышленная Автоматизация:</b> Мы предлагаем решения для автоматизации производственных процессов.
                        </p>
                    </div>
                </div>
                <div className={styles.product}>
                    <h2>Решения</h2>
                    <div className={`${styles.productItem} ${styles.productItemClickable}`}>
                        <HashLink to='/AnalyticsService'>
                            <p>
                                <b>Интеллектуальная Система Видеонаблюдения с ИИ-Аналитикой:</b> Авангардное решение в области объектовой безопасности, интегрирующее глубокое обучение для распознавания и анализа видеоданных в реальном времени.
                            </p>
                            <div className={styles.productItemMainImg}>
                                <img src={require('../images/icons/right-arrow-icon.png')} alt="" />
                            </div>
                            <div className={styles.productItemHoverImg}>
                                <img src={require('../images/icons/right-arrow-white-icon.png')} alt="" />
                            </div>
                        </HashLink>
                    </div>
                    <div className={`${styles.productItem} ${styles.productItemClickable}`}>
                        <HashLink to='/SecurityService'>
                            <p>
                                <b>Персонализированный Веб-Интерфейс для Системы Видеонаблюдения:</b> Доступ к функциям управления и мониторинга видеонаблюдения для пользователей, предоставляемый провайдером услуг.
                            </p>
                            <div className={styles.productItemMainImg}>
                                <img src={require('../images/icons/right-arrow-icon.png')} alt="" />
                            </div>
                            <div className={styles.productItemHoverImg}>
                                <img src={require('../images/icons/right-arrow-white-icon.png')} alt="" />
                            </div>
                        </HashLink>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Многоканальная платформа SDR:</b> Гибкая и масштабируемая SDR-система, поддерживающая обработку множественных радиочастотных сигналов параллельно.
                        </p>
                    </div>
                    <div className={styles.productItem}>
                        <p>
                            <b>Автоматизированная система управления процессом армирования акриловых ванн:</b> Инновационное решение для оптимизации и контроля производственного процесса армирования.
                        </p>
                    </div>
                </div>
        </div>
    );
}

export default Products;