import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import styles from '../styles/Contacts.module.css'

const Contacts = () => {
    return (
        <div className="content-container">
            <div className={styles.contactsContainer}>
                <div className={styles.contactsWave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={styles.contactsWaveShapeFill}></path>
                    </svg>
                </div>
                <h1>Контакты</h1>
                <div className={styles.contactsContainerContent}>
                    <div className={styles.contactsContentWrapper}>
                        <div className={styles.contactsContentLeftSide}>
                            <div className={styles.contactInfo}>
                                <h3 className={styles.contactTitle}>Как нас найти</h3>
                                <p className={`${styles.contactText} ${styles.contactsRight}`}>600000, г. Владимир, ул. Белоконской, д. 3, ауд. 425-2 (2-й корпус ВлГУ)</p>
                            </div>
                            <div className={styles.contactInfo}>
                                <h3 className={styles.contactTitle}>Связаться с нами</h3>
                                <div className={styles.contactItem}>
                                    <img src={require('../images/icons/phone-icon.png')} alt="" />
                                    <p className={styles.contactText}>+ 7 (4922) 47-51-47</p>
                                </div>
                                <div className={styles.contactItem}>
                                    <img src={require('../images/icons/email-icon.png')} alt="" />
                                    <p className={styles.contactText}>dsp-club@yandex.ru</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.contactsContentRightSide}>
                            <div className={styles.contactInfo}>
                                <h3 className={styles.contactTitle}>Юридический адрес</h3>
                                <p className={`${styles.contactText} ${styles.contactsRight}`}>600005, г. Владимир, ул. Александра Матросова, д. 28б, помещение № 9, офис № 216</p>
                                <p className={`${styles.contactText} ${styles.contactsRight}`}>ИНН: 3328021060</p>
                                <p className={`${styles.contactText} ${styles.contactsRight}`}>КПП: 332801001</p>
                                <p className={`${styles.contactText} ${styles.contactsRight}`}>ОГРН: 1183328010359</p>
                            </div>
                        </div>
                    </div>
                    <YMaps>
                        <Map className={styles.infoMap} defaultState={{center: [56.146011, 40.374358], zoom: 16}}>
                            <Placemark defaultGeometry={[56.146011, 40.374358]}></Placemark>
                        </Map>
                    </YMaps>
                </div>
                <div className={styles.contactsBottomWave}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={styles.contactsBottomWaveShapeFill}></path>
                    </svg>
                </div>
            </div>
        </div>
    );
}
 
export default Contacts;