import styles from '../styles/Contractors.module.css'

const Contractors = () => {
    return (
        <div className="content-container">
            <h1>Партнёры</h1>
            <div className={styles.contractorsContainer}>
                <a className={styles.contractor} href="https://www.vlsu.ru/" target="_blank">
                    <h4 className={styles.contractorTitle}>Владимирский государственный университет имени Александра Григорьевича и Николая Григорьевича Столетовых</h4>
                    <p className={styles.contractorText}>Высшее учебное заведение Владимира, крупнейший вуз Владимирской области и один из крупнейших в ЦФО</p>
                </a>
                <a className={styles.contractor} href="https://radomir.ru/" target="_blank">
                    <h4 className={styles.contractorTitle}>ООО "Радомир"</h4>
                    <p className={styles.contractorText}>Компания «Радомир» сформировалась в 1991 году. Уже тогда компания реализовала один из первых в России инновационных проектов – производство акриловых и гидромассажных ванн.</p>
                </a>
                <a className={styles.contractor} href="https://spm33.ru/" target="_blank">
                    <h4 className={styles.contractorTitle}>АНО «Служба мониторинга по Владимирской области»</h4>
                    <p className={styles.contractorText}> Основным направлением деятельности АНО на территории Владимирской области является развитие комплексной системы цифрового мониторинга безопасности объектов, в том числе объектов жилищно-коммунального хозяйства.</p>
                </a>
                <a className={styles.contractor} href="https://signaltec.ru/" target="_blank">
                    <h4 className={styles.contractorTitle}>АО "Сигналтек"</h4>
                    <p className={styles.contractorText}>АО «СИГНАЛТЕК» является одним из ведущих российских разработчиков профессионального оборудования в области систем сбора, распределения и анализа трафика и пользовательских данных.</p>
                </a>
            </div>
        </div>
    );
}
 
export default Contractors;