import { useEffect, useState } from 'react'
import styles from '../styles/ScrollButton.module.css'

const ScrollButton = () => {

    const [display, setDisplay] = useState('none');
    const [height, setHeight] = useState(0)

    const handleClick = () => {
        window. scrollTo({top: 0, behavior: 'smooth'})
    }

    const scrollHandler = () => {
        const heightToShow = 400;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToShow) {  
            setDisplay('block')
       } else {
            setDisplay('none');
       }  
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [])
    
    
    return (
        <button className={styles.scrollButton} style={{display: display}} onClick={handleClick}><img src={require('../images/arrow.png')} alt="" /></button>
    );
}
 
export default ScrollButton;